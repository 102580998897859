@import '../theme/colours';

.errorMark {
  line-height: 0;
  padding: 14px 18.7px 0px 18.7px;
  color: $color-Error;
}

.doneMark {
  line-height: 0;
  padding: 14px 18.7px 0px 18.7px;
  color: $color-Success;
}

.hourGlass {
  color: $color-NG-NeutralsGrey;
  line-height: 0;
  padding: 16px 18px 0;
}

.notificationProgress {
  margin-top: 20px;
  width: 100%;
  height: 5px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  background: $color-Success;

  &.tableProgress {
    margin-top: 5px;
  }
}

.bar {
  position: absolute;
  width: calc(100% * 2 / 7);
  height: 100%;
  display: flex;
  animation: move 2s linear infinite;
  background-color: $color-NGLight1;
}

@keyframes move {
  0% {
    transform: translate(-100%, 0);
  }
  100% {
    transform: translate(calc(7 / 2 * 100%), 0);
  }
}
