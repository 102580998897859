@import '../theme/colours';
@import '../theme/fonts';
@import '../theme/sizes';

.table {
  width: 100%;
  border-radius: 6px;
  background-color: $color-White;
  border-spacing: 0;
  text-align: left;

  tbody tr {
    border: solid 1px $color-NGLight3;

    &:nth-child(even) {
      background-color: $color-NGLight3;
    }
  }
}

.parentChildTable {
  border-radius: 0px 6px 6px 6px;
  border: solid 1px #edeff2;
  th {
    border-bottom: none;
  }
  td {
    border-top: solid 1px #edeff2;
    border-bottom: none;
  }
}

.pagination {
  text-align: right;
  padding: 16px;
}

.tableHeaderCell {
  color: $color-NG-NeutralsGrey;
  padding: 15px 16px;
  border-bottom: solid 2px $color-NGLight3;
  white-space: nowrap;
}

.tableCell {
  min-height: 60px;
  color: $color-NGDark1;
  padding: 10px 16px;
  border-bottom: solid 2px $color-NGLight3;
}

.actionsTableCell {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: inherit;
  box-sizing: border-box;
  text-align: right;
}

.actionsArea {
  text-align: right;
}

.pageHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: $color-NG-NeutralsGrey;
}

.getHelp {
  color: $color-Error;

  > *:first-child {
    font-size: 18px;
    vertical-align: top;
  }
}

.availabilityDate {
  color: $color-NG-NeutralsGrey;
}

.articlesFilter {
  display: flex;
}

.replacementFileWarning {
  color: $color-Error;
  margin-right: 5px;
  vertical-align: middle;
}

.newFileWarning {
  color: $color-Primary;
  margin-left: 5px;
  vertical-align: middle;
}

.noSessionContainer,
.noArticleContainer {
  width: 100%;
  background-color: $color-White;
  display: flex;
  flex: 1;
  margin-bottom: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 6px 6px;
}

.noSessionMessage,
.noArticleMessage {
  display: inline-block;
  text-align: center;
  line-height: 2;
}

.sessionsTabList {
  z-index: 0;
  display: flex;
  flex-wrap: wrap;
}

.sessionsTabButton {
  border-top: $color-Primary 3px solid;
  font-size: 16px;
  color: $color-Primary;
  font-weight: 600;
  border: 1px solid $color-NGLight2;
  border-bottom: none;
  min-height: 47px;
  min-width: 194px;
  margin: 0px;
  text-align: center;
  background-color: #f7f7f7;
  justify-content: center;
  &:hover {
    border-bottom: none;
  }
  &:first-child {
    border-right: none;
    border-radius: 2px 0px 0px 0px;
  }
}

.activeTab {
  border-top: $color-PrimaryDark1 3px solid;
  background-color: white;
  z-index: 1;
}

.dialog {
  width: 700px;
  border-radius: 8px;
}

.dialogHeader {
  font-size: 1.4rem;
}

.dialogContent {
  color: #5c6066;
  font-size: 1.1rem;
  min-height: 120px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dialogActions {
  display: flex;
  justify-content: flex-end;
}

.cancelButton {
  margin-right: 16px;
}

.centresListButton {
  color: $color-PrimaryDark1;
  font-size: 16px;
  font-weight: 400;
  background-color: inherit;
  border: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    background-color: inherit;
  }
  &:active {
    background-color: inherit;
  }
}

.childReportDownloadButton {
  justify-content: flex-end;
  align-self: center;
  display: block;
  margin-left: auto;
  margin-right: 0;
}