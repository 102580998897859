$font-family-source-sans-pro: "SourceSansPro";

@mixin source-sans-pro-font-face {
  @font-face {
    font-family: $font-family-source-sans-pro;
    src: local("SourceSansPro"), url(../fonts/SourceSansPro-Regular.ttf) format("truetype"),
      url(../fonts/SourceSansPro-Regular.eot), url(../fonts/SourceSansPro-Regular.svg),
      url(../fonts/SourceSansPro-Regular.woff) format("woff"), url(../fonts/SourceSansPro-Regular.woff2) format("woff2");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  @font-face {
    font-family: $font-family-source-sans-pro;
    src: local("SourceSansPro"), url(../fonts/SourceSansPro-SemiBold.ttf) format("truetype"),
    url(../fonts/SourceSansPro-SemiBold.eot), url(../fonts/SourceSansPro-SemiBold.woff) format("woff"), 
    url(../fonts/SourceSansPro-SemiBold.woff2) format("woff2");
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  @font-face {
    font-family: $font-family-source-sans-pro;
    src: local("SourceSansPro"), url(../fonts/SourceSansPro-Bold.ttf) format("truetype"),
      url(../fonts/SourceSansPro-Bold.eot), url(../fonts/SourceSansPro-Bold.svg),
      url(../fonts/SourceSansPro-Bold.woff) format("woff"), url(../fonts/SourceSansPro-Bold.woff2) format("woff2");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }
}
