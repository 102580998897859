.bar {
  display: flex;
  flex-direction: column;
}

.progressBar {
  background-color: #76c7c0;
  height: 5px;
  border-radius: 5px;
  transition: width 0.3s ease;
}

.progressText {
  text-align: right;
}
