@import '../../theme/colours';
@import '../../theme/fonts';

.input {
  width: 100%;
  height: 44px;
  border: 1px solid $color-NGLight2;
  margin: 0;
  min-width: 0;
  border-radius: 6px;
  background: white;
  box-sizing: content-box;
  letter-spacing: inherit;
  font-size: 16px;
  color: $color-NGDark1;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
  }

  &:focus {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    outline: none;
    border-color: $color-NGLight1;
  }

  &.focusedContainer {
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.05);
    border-color: $color-NGLight1;
  }
}

.datePicker {
  width: 100%;
  height: 44px;
  box-sizing: content-box;
}

.filterContainer {
  display: flex;
  background-color: $color-NGLight3;
  align-items: flex-start;
  min-height: 150px;
  margin-top: 2px;
  padding-top: 38px;
  box-sizing: border-box;
}

.inputContainer {
  position: static;
  flex: 2 1;
  margin-left: 24px;
  min-height: 74px;
  align-items: center;
}

.centreComboBox {
  flex: 2 1;
  width: 100%;
  min-height: 44px;
  box-sizing: content-box;
}

.datePickerContainer {
  flex: 1 1;
  margin-left: 24px;
  margin-right: 24px;
  height: 74px;
}

.singleDatePickerContainer {
  flex: 0 671px;
}

.label {
  font-size: 16px;
  line-height: 1.38;
  color: $color-NG-NeutralsGrey;
  font-weight: bold;
}

.labelContainer {
  margin: 0 16px 6px 2px;
}

.graphImage {
  width: 100%;
}

.reportsWrapper {
  display: flex;
  flex-direction: column;
  font-family: $font-family-source-sans-pro;
  background-color: $color-White;
  height: 800px;
  padding: 10px 10px;
  margin-bottom: 20px;
  position: relative;
  border-radius: 6px;
  border: solid 1px $color-NGLight2;
  color: rgba(0, 0, 0, 0.87);
  overflow-wrap: anywhere;
}

.reportsWrapperWithTabs {
  @extend .reportsWrapper;
  padding: 0 10px 10px 10px;
}

.graphImageContainer {
  margin-top: 50px;
  max-height: 300px;
  overflow: hidden;
}

.downloadButton {
  margin-top: 7%;
  align-self: center;
}
